import { Button, Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";



export default function WelcomePage() {

    let navigate = useNavigate()
    const { Title, Paragraph } = Typography;

    const handleSubmit = () => {
        navigate('/assessment/survey')
    }

    const paddingDefault = { padding: "16px" }
    const rightSection = { padding: "16px", borderLeft: "7px solid #fff" }

    return <Row justify="center" align="middle" style={{ height: "100vh", backgroundColor: "#0a0a19" }}>
        <Col sm={{ span: 6 }} lg={{ span: 12 }} style={paddingDefault}>
            <Row justify="end">
                <Col>
                    <Title style={{ color: "white" }}>Cloud 2.0 Assessment</Title>
                </Col>
            </Row>
        </Col>
        <Col sm={{ span: 6 }} lg={{ span: 12 }} style={rightSection}>
            <Row justify="start" gutter={[8, 8]}>
                <Col sm={{ span: 12, offset: 2 }} lg={{ span: 12, offset: 0 }}>
                    <Paragraph style={{ color: "white" }}>Descubra agora o seu score de quão moderna está a sua adoção de nuvem. Será que sua empresa consegue bater nossos especialistas?</Paragraph>
                    <Button onClick={handleSubmit} type="default" >Vamos começar</Button>
                </Col>
            </Row>
        </Col>
    </Row>
}