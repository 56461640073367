import React from "react";
import { Result, Card } from "antd";


const FinishedPage = () => {



    return (<Card style={{ boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)' }}>
        <Result
            status='success'
            title='Enviado com sucesso'
            subTitle='Agradecemos o envio das informações! Agora nossa equipe de especialistas analisará e, em breve, enviará o resultado
            para o seu email cadastrado.'
            key={0}
            extra={[<>
                <p>
                    Enquanto isso, fique a vontade para dar uma olhada em nosso <a href="https://wp.kxp.consulting/saibamais/" style={{ color: '#4573D2', textDecoration: 'underline' }}>site</a>
                </p>

            </>
            ]}>

        </Result>
    </Card>
    )
}

export default FinishedPage