import React, { useEffect, useState } from "react";
import { Form, Input, Select } from 'antd'
import axios from "axios";
import DropDown from "./Industry";
const { Option } = Select





const FormItem = ({ label, descript, message }) => {


  return (

    <Form.Item
      label={label}
      name={descript}
      rules={[
        {

          required: true,
          message: { message },
        },
      ]}
    >
      <Input />
    </Form.Item>

  )
}




const FormSelect = ({ label, descript, message, Industrys, form }) => {





  return (
    <Form.Item

      label={label}
      name={descript}
      rules={[
        {

          required: true,
          message: { message },
        },
      ]}
    >
      <DropDown data={Industrys} form={form}></DropDown>
    </Form.Item>
  )
}
export { FormItem, FormSelect }