import { Card, Row } from "antd";
import axios from "axios";
import { React, useEffect, useState } from "react";
import { Chart } from "react-google-charts";







export const options = {
    chart: {
        title: "Resultados",
        subtitle: "",
        filterColumnIndex: 0,
        matchType: "any",
    },


};

const Test = () => {

    const [chartData, setChartData] = useState([])

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        (async () => {
            const response = await axios.get("https://assessment-api-prod-01.kxpconsulting.com/survey-entry/e6163ac7-d2ea-4db7-bef2-684f59b1efa7")
            const handleData = response.data.answers.map((p) => {
                return [
                    p.question.title,
                    p.selectedOption.score
                ]
            })

            setChartData([["Questions", "Pontos Alcançados"], ...handleData])
            setIsLoading(false)
        })()
    }, [])
    if (isLoading) {
        return <p level={4}>Loading...</p>
    }
    return (
        <>
            <Card style={{ width: '100%', }}>
                <Chart

                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={chartData}
                    options={options}
                    chartPackages={["corechart", "controls"]}
                />
            </Card>


        </>
    );
}


export default Test