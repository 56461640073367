import { Col, Radio, Space, Typography } from "antd"
function QuestionOption({ data }) {
    const { Text } = Typography

    return <Radio value={data.id}><Text>{data.title}</Text></Radio>
}

export default function Question({ data, onValueChange }) {

    const { Title, Paragraph } = Typography

    const onChange = (e) => {
        onValueChange({ questionId: data.id, selectedQuestionOptionId: e.target.value });
    };

    return <Col style={{ marginTop: "24px" }}>
        <Title level={4}>{data.title}</Title>
        <Paragraph>{data.description}</Paragraph>

        <Radio.Group onChange={onChange} >
            <Space direction="vertical" >
                {data.questionOptions.map(questionOption => <QuestionOption key={questionOption.id} data={questionOption} />)}
            </Space>
        </Radio.Group>


    </Col>
}