
import React from "react";
import { Layout, Row, Col, Image } from "antd";
import { Content, Header } from "antd/lib/layout/layout";

import logo from './img/KXP-logo.png'
import { Outlet } from "react-router-dom";



const BaseLayout = () => {
    return (

        <Layout style={{ width: '100%', heigth: '100vh' }}>
            <Header color="primary" style={{ height: "auto" }}>
                <Row style={{ padding: "8px" }}>
                    <Image src={logo} style={{ height: "auto", width: "100%", maxWidth: "160px" }} preview={false}></Image>
                </Row>
            </Header>
            <Content style={{ minHeight: '100vh', padding: 24, margin: 0, }}>
                <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Col sm={{ span: 24 }} lg={{ span: 8 }}>
                        <Outlet />
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
export default BaseLayout