import React from "react";
import { Card, Row, Col } from "antd";
import Formulario from '../Components/Form'





const FormPage = () => {




    return (<Row justify="center">
        <Col md={24} >
            <Card style={{ width: '100%', boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)' }} >

                <Formulario></Formulario>
            </Card>
        </Col>
    </Row>
    )
}
export default FormPage