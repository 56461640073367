import { OmitProps } from "antd/lib/transfer/ListBody"
import React, { useState } from "react"

const ValueContext = React.createContext({})

function ValueProvider(props) {
    const [answers, setAnswers] = useState([])

    return (
        <ValueContext.Provider value={{ answers, setAnswers }}>
            {props.children}
        </ValueContext.Provider>
    )


}

export { ValueContext, ValueProvider }