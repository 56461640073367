import React from "react";
import { useState, useEffect } from "react";
import { Form, Button, Row, Col, Typography } from 'antd'
import { FormItem, FormSelect } from "./FormItem"
import { ValueContext } from "../Contexts/Context";
import { useNavigate } from "react-router-dom"
import axios from "axios";
import { LoadingOutlined } from '@ant-design/icons';
const { Title } = Typography

const Formulario = () => {
  let navigate = useNavigate()
  const { answers } = React.useContext(ValueContext)
  const [Industrys, setIndustrys] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [form] = Form.useForm()

  useEffect(() => {
    (async () => {
      const industryList = await axios.get('https://assessment-api-prod-01.kxpconsulting.com/industry')
      setIndustrys(industryList.data)
      setIsLoading(false)

    })()
  }, [])

  const onFinish = async (values) => {
    console.log('Success:', values);
    console.log(answers)
    await axios.post(' https://assessment-api-prod-01.kxpconsulting.com/survey-entry', {

      firstName: values.FirstName,
      lastName: values.LastName,
      corporateEmail: values.CorporateEmail,
      industryId: values.Vertical,
      answers: answers

    })
      .then(() => {
        navigate('/assessment/finished')
        console.log('Post efetuado com sucesso')
      })
      .catch(() => {
        console.log('Post falhou')
      })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }

  if (isLoading) {
    return <Title level={4}>Loading...<LoadingOutlined /></Title>
  }

  return (

    <Form
      name="basic"
      form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}

      autoComplete="off"
    >
      <FormItem label='First Name' descript='FirstName' message='Please Complete FirstName!'></FormItem>
      <FormItem label='Last Name' descript='LastName' message='Please Complete LastName!'></FormItem>
      <FormItem label='Corporate Email' descript='CorporateEmail' message='Please Complete Corporate Email!'  ></FormItem>
      <FormSelect label='Vertical' descript='Vertical' message='Please Complete Vertical!' Industrys={Industrys} form={form}></FormSelect>
      <Row style={{ justifyContent: 'end' }}>
        <Col>
          <Button htmlType="submit" type="primary">Continue</Button>
        </Col>
      </Row>

    </Form>
  )

}
export default Formulario