import { Select, Form } from "antd";
const { Option } = Select

export default function DropDown({ data, form }) {


    function handleChange(e) {
        form.setFieldsValue({ Vertical: e })
    }


    return (
        <Select onChange={handleChange}>

            {data.map(industryOption => {
                return (
                    <Option
                        value={industryOption.id}
                        key={industryOption.id}

                    >{industryOption.title}</Option>)
            }
            )}


        </Select>)
}