import React from 'react';
import WelcomePage from './Pages/WelcomePage';
import BaseLayout from './Components/BaseLayout';
import FormPage from './Pages/FormPage';
import SurveyPage from './Pages/SurveyPage';
import Grafico from './Pages/Grafico';
import FinishedPage from './Pages/FinishedPage';
import { ValueProvider } from './Contexts/Context'
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

function App() {


  return (
    <ValueProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<WelcomePage />} />
          <Route path='/assessment' element={<BaseLayout />}>
            <Route path='cadastro' element={<FormPage />} />
            <Route path='survey' element={<SurveyPage />} />
            <Route path='finished' element={<FinishedPage />} />
            <Route path='test' element={<Grafico />} />
          </Route>

        </Routes>
      </BrowserRouter>
    </ValueProvider>

  )
}

export default App;



