import React from "react"
import { Button, Card, Col, Pagination, Row, Typography } from "antd"
import { useEffect, useState } from "react"
import Question from "../Components/Question"
import axios from 'axios'
import { ValueContext } from "../Contexts/Context"
import { useNavigate } from "react-router-dom"
import { LoadingOutlined } from '@ant-design/icons';

const SurveyPage = () => {


    let navigate = useNavigate()

    const { Title } = Typography
    const { answers, setAnswers } = React.useContext(ValueContext)
    const [current, setCurrent] = useState(0);
    const [questions, setQuestions] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [selectedQuestion, setSelectedQuestion] = useState({})
    const [currentPage, setCurrentPage] = useState(1)


    const next = () => {
        const newAnswerList = answers.filter(x => x.id !== selectedQuestion.questionId)
        setAnswers([...newAnswerList, { questionId: selectedQuestion.questionId, selectedQuestionOptionId: selectedQuestion.selectedQuestionOptionId }])
        setCurrentPage(currentPage + 1)
        if (current === questions.length - 1) {
            navigate('/assessment/cadastro')
        }
        else {

            setSelectedQuestion({})
            setCurrent(current + 1)


        }


    };

    const prev = () => {
        setCurrent(current - 1);
        setCurrentPage(currentPage - 1)
    };





    useEffect(() => {
        (async () => {
            const questionList = await axios.get('https://assessment-api-prod-01.kxpconsulting.com/question')
            setQuestions(questionList.data)
            setIsLoading(false)

        })()
    }, [])






    if (isLoading) {
        return <Title level={4}>Loading...<LoadingOutlined /></Title>
    }

    return <Card style={{ boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)' }}>
        <Col span={24}>
            <Row>
                <Pagination disabled simple defaultCurrent={1} current={currentPage} total={questions.length * 10}></Pagination>
                <Question key={current} data={questions[current]} onValueChange={setSelectedQuestion} />
            </Row>
            <Row style={{ marginTop: "16px" }} justify="end">
                {current > 0 && <Button onClick={prev}>Back</Button>}
                {current === (questions.length - 1) ?
                    <Button type="primary" style={{ marginLeft: "8px" }} onClick={next} disabled={questions.filter(x => x.id === selectedQuestion.questionId).length === 0}>Submit</Button> :
                    <Button type="primary" style={{ marginLeft: "8px" }} onClick={next} disabled={questions.filter(x => x.id === selectedQuestion.questionId).length === 0}>Next</Button>
                }
            </Row>
        </Col>
    </Card>
}

export default SurveyPage